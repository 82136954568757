<template>
  <a 
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--bubble-ripple2">
      <div class="ph-inpage-widget__circle">
        <div class="ph-inpage-widget__circle-thumb">
          <img v-show="!imgIcon" :src="require('@/assets/panel/images/inpage/empty-white.svg')" alt="">
          <img :src="imgIcon" v-show="imgIcon" alt="">
        </div>
        <div class="ph-inpage-widget__circle-border"></div>
        <div class="ph-inpage-widget__figure ph-inpage-widget__figure-1">
          <img :src="require('@/assets/panel/images/inpage/heart.svg')" alt="">
        </div>
        <div class="ph-inpage-widget__figure ph-inpage-widget__figure-2">
          <img :src="require('@/assets/panel/images/inpage/heart.svg')" alt="">
        </div>
        <div class="ph-inpage-widget__figure ph-inpage-widget__figure-3">
          <img :src="require('@/assets/panel/images/inpage/heart.svg')" alt="">
        </div>
        <div class="ph-inpage-widget__close">&#10005;</div>
      </div>
    </a>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>